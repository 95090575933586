<!--
 * @Author: lihuaruiz lihuarui@huawei.com
 * @Date: 2024-06-04 16:21:22
 * @LastEditors: lihuaruiz lihuarui@huawei.com
 * @LastEditTime: 2024-07-08 10:57:09
 * @FilePath: \vue2Admin\src\views\systemsetting\account\detail.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
vue
<template>
  <div>
    <!-- <div class="back-button" @click="goBack">
      <i class="el-icon-back"></i><span>详情</span>
    </div> -->
    <div class="detail-content" style="padding: 0px 15px 25px 15px">
      <!-- <p style="margin: 0px; font-size: 20px; font-weight: 800">邀约任务详情</p> -->
      <el-row>
        <el-col class="item"
          ><div class="term">车源ID:</div>
          <div class="detail">{{ itemDetail.id }}</div></el-col
        >
        <el-col class="item"
          ><div class="term">车型:</div>
          <div class="detail">{{ itemDetail.model }}</div></el-col
        >
        <el-col class="item"
          ><div class="term" style="width: 95px">上牌时间:</div>
          <div class="detail">
            {{ itemDetail.registrationTime }}
          </div></el-col
        >
        <el-col class="item"
          ><div class="term">行驶里程:</div>
          <div class="detail">{{ itemDetail.kilometre }}</div></el-col
        >
        <el-col class="item"
          ><div class="term">所在城市:</div>
          <div class="detail">{{ itemDetail.city }}</div></el-col
        >
        <el-col class="item"
          ><div class="term">线索下发时间:</div>
          <div class="detail">{{ itemDetail.issueTime }}</div></el-col
        >
        <el-col class="item" style="width: 355px"
          ><div class="term">新车指导价/卖车暂估价:</div>
          <div class="detail">
            {{ itemDetail.guidingPrice + "/" + itemDetail.estimate }}
          </div></el-col
        >
        <el-col class="item"
          ><div class="term">电话：</div>
          <div class="detail">{{ itemDetail.phone }}</div>
          <!-- <script
            type="text/javascript"
            src="{host}/clink-client.js?id={identifier}"
          ></script>
          <script
            type="text/javascript"
            src="{host}/clink-client-ui-vue/ClinkToolbar.umd.min.js"
          ></script> -->
          <!-- <i class="el-icon-phone" style="font-size: 20px;" ></i> -->
          <img
            style="width: 20px; height: 20px; cursor: pointer"
            src="../../assets/dianhua.png"
            @click="dialNumber(itemDetail.tel)"
          />
        </el-col>
        <el-col class="item" style="width: 350px; margin-right: 20px">
          <div class="term" style="width: 115px">下次跟进时间:</div>
          <el-date-picker
            v-model="value3"
            type="datetime"
            placeholder="选择日期时间"
            value-format="yyyy-MM-dd HH:mm:ss"
            default-time="12:00:00"
          >
          </el-date-picker>
        </el-col>
        <el-col class="item" style="width: 450px; display: flex"
          ><div class="term">工单处理:</div>
          <div style="margin-left: 10px">
            <el-button @click="issuedTickets(detailId)">下发工单</el-button
            ><el-button @click="followUp(detailId, value3)">下次跟进</el-button
            ><el-button @click="temporarily(detailId)">暂时不卖</el-button>
          </div></el-col
        >
        <el-col
          v-if="isShow"
          style="width: 450px; display: flex; margin-top: 25px"
          ><div class="term">申诉处理:</div>
          <div style="margin-left: 10px">
            <el-button @click="auditResults(detailCid, detailSid, passVal)"
              >通过</el-button
            ><el-button @click="auditResults(detailCid, detailSid, failedVal)"
              >驳回</el-button
            >
          </div></el-col
        >
        <el-col style="width: 80%; display: flex; margin-top: 25px"
          ><div class="term">跟进记录:</div>
          <el-input
            style="width: 500px"
            type="textarea"
            :rows="3"
            placeholder="请输入内容"
            v-model="textarea"
            maxlength="300"
            show-word-limit
            @blur="recordKeeping(detailId, textarea)"
          ></el-input
        ></el-col>
      </el-row>
      <!-- <div v-if="title" class="title">{{ title }}</div> -->
      <!-- <el-row :gutter="gutter">
        <el-col :key="key" :span="+col" v-for="(item, key) in itemDetail">
          <div class="term">{{ item.term }}</div>
          <div class="detail">
            {{ item.detail
            }}<image
              src="../../assets/dianhua.png"
              v-show="dataSource.phone"
            ></image>
          </div>
        </el-col>
      </el-row> -->
    </div>
    <div class="detail-content">
      <el-menu
        :default-active="activeIndex"
        class="el-menu-demo"
        mode="horizontal"
        @select="handleSelect"
      >
        <el-menu-item index="1">跟进记录</el-menu-item>
        <el-menu-item index="2">通话记录</el-menu-item>
      </el-menu>
      <div class="line"></div>
      <el-table
        :data="tableData"
        stripe
        style="width: 100%; margin-top: 10px"
        :header-cell-style="{ background: '#eef1f6', color: '#606266' }"
      >
        <el-table-column label="序号" width="70" align="left">
          <template slot-scope="scope">
            {{ scope.$index + 1 }}
          </template>
        </el-table-column>
        <el-table-column
          v-for="tab in this.activeIndex == 1 ? tableTitle1 : tableTitle2"
          :key="tab.code"
          :label="tab.name"
          show-overflow-tooltip
          :width="tab.width"
        >
          <template slot-scope="scope">
            <span>{{ scope.row[tab.code] }} </span>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
import {
  getInvitationDetail,
  getissuedTickets,
  getFollowUp,
  getTemporarily,
  getAuditResults,
  addRecordKeeping,
  getCallLogs,
  getFollowupRecords,
} from "../../api/common";
import router from "../../router";

export default {
  data() {
    return {
      itemDetail: {},
      activeIndex: "1",
      detailId: this.$route.params.id
        ? this.$route.params.id
        : localStorage.getItem("id"),
      tableData: [],
      listData: [],
      listData2: [],
      value1: "",
      value3: "",
      textarea: "",
      passVal: "通过",
      failedVal: "未通过",
      detailCid: this.$route.params.cid
        ? this.$route.params.cid
        : localStorage.getItem("cid"),
      detailSid: this.$route.params.sid
        ? this.$route.params.sid
        : localStorage.getItem("sid"),

      isShow: this.$route.params.isShow ? this.$route.params.isShow : false,
      tableTitle1: [
        { name: "操作时间", code: "deliveryTime", width: "" },
        { name: "接通状态", code: "auditState", width: "" },
        { name: "跟进结果", code: "result", width: "" },
        { name: "下次跟进时间", code: "appealTime", width: "" },
      ],
      tableTitle2: [
        { name: "通话ID", code: "id", width: "" },
        // { name: "通话环节", code: "model", width: "" },
        { name: "客服", code: "kid", width: "" },
        { name: "通话对象", code: "sid", width: "" },
        { name: "通话时间", code: "callTime", width: "" },
        { name: "通话时长", code: "callDuration", width: "" },
        { name: "接通状态", code: "relationState", width: "" },
        { name: "操作", code: "contactState", width: "" },
      ],
    };
  },
  components: {
    // DescriptionList,
  },
  mounted() {
    this.getItemDetail(this.detailId);
    this.login();
    this.pre(this.itemDetail.phone);
    console.log(localStorage.getItem("id"));
  },
  methods: {
    // 关闭标签事件
    goBack() {
      // 判断路由是否能返回
      if (window.history.length <= 2) {
        this.goHome();
      } else {
        this.$router.go(-1);
      }
    },
    getItemDetail(id) {
      getInvitationDetail(id).then((res) => {
        this.itemDetail = res.data.userinfo;
        console.log(this.itemDetail);
        this.textarea = this.itemDetail.serviceRecord;
        this.itemDetail.tel = this.itemDetail.phone;
        this.itemDetail.phone =
          this.itemDetail.phone.slice(0, 3) +
          "****" +
          this.itemDetail.phone.slice(7, 11);

        this.itemDetail.registrationTime =
          this.itemDetail.registrationTime.slice(0, 7);
      });

      const params = {
        sid: id,
      };
      // getFollowupRecords(params).then((res) => {
      //   this.listData = res.remark.commercial;
      //   this.tableData = this.listData;
      //   console.log(this.listData);
      // });
      getCallLogs(params).then((res) => {
        console.log(this.listData2);
        this.listData2 = res.data.queryResult.list;
      });
    },
    handleSelect(key) {
      this.activeIndex = key;
      this.tableData = this.activeIndex == 1 ? this.listData : this.listData2;
    },
    //下发工单
    issuedTickets(id) {
      getissuedTickets(id).then((res) => {
        if (res.data.code == 10001) {
          router.push("/login");
          this.msgerror("登陆超时请重新登录！");
        } else {
          this.itemDetail = res;
          if (res.data.success == true) {
            this.$message({ message: res.data.message, type: "success" });
          } else {
            this.$message.error(res.data.message);
          }
        }
      });
    },
    //下次跟进
    followUp(id, value3) {
      if (value3 == "" || value3 == null) {
        this.$message.error("请先选择下次跟进时间再点击跟进！ ");
      } else {
        getFollowUp(id, value3).then((res) => {
          if (res.data.code == 10001) {
            router.push("/login");
            this.msgerror("登陆超时请重新登录！");
          } else {
            //执行成功后代码处理
            if (res.data.code == 10000) {
              this.itemDetail = res;
              this.msgsuccess("操作成功！");
            } else {
              this.msgerror("操作失败！");
            }
          }
        });
      }
    },
    //暂时不卖
    temporarily(id) {
      getTemporarily(id).then((res) => {
        if (res.data.code == 10001) {
          router.push("/login");
          this.$message.error("登陆超时请重新登录！");
        } else {
          this.itemDetail = res;
          if (res.success == true) {
            this.$message({ message: res.message, type: "success" });
          } else {
            this.$message.error(res.data.message);
          }
        }
      });
    },
    auditResults(cid, sid, val) {
      getAuditResults(cid, sid, val).then((res) => {
        //执行成功后代码处理
        if (res.data.code == 10000) {
          this.$message({ message: res.data.message, type: "success" });
        } else {
          this.$message.error(res.data.message);
        }
      });
    },
    getPath(params) {
      var arr = [];
      for (var key in params) {
        arr.push(key + "=" + params[key]);
      }
      var path = arr.join("&");
      path = path.substring(0, path.length - 1);
      return "tinet://startapp/here?" + path;
    },
    login() {
      var params = {};
      // 企业编号
      params.identifier = "chesm";
      // 座席工号
      params.cno = "2000";
      // 座席密码
      params.password = "Aa112233@";
      // 绑定类型
      params.bindType = 3;
      // 绑定号码
      params.bindTel = "2000";
      // 座席初始状态
      params.initStatus = 1;
      // 指定平台
      params.platform = 2;
      // 操作类型
      params.handleType = "login";
      this.creatA(params);
    },
    creatA(params) {
      var eleLink = document.createElement("a");
      eleLink.style.display = "none";
      eleLink.href = this.getPath(params);
      // 触发点击
      document.body.appendChild(eleLink);
      eleLink.click();
    },
    pre(params) {
      // params.tel = this.tel;

      // 客户号码加密外呼号码格式示例
      // params.tel = 'TZGIxMWRmOWVkZDg5YjA1NTRmY2JiNzVlODc0YTkyNWM=';

      // 呼叫座席设备超时时长
      params.timeout = 30;
      // 呼叫客户设备超时时长
      params.dialTelTimeout = 45;
      // 用户自定义字段
      // params.userField =
      //   '{"your_key_1":"your_value_1","your_key_2":"your_value_2"}';
      // 操作类型
      params.handleType = "previewOutcall";

      this.creatA(params);
    },
    dialNumber(phone) {
      this.previewOutcall(phone);
    },
    //跟进记录失焦保存
    recordKeeping(sid, serviceRecord) {
      const kid = localStorage.getItem("kid");
      addRecordKeeping(sid, serviceRecord, kid).then((res) => {
        console.log(res);

        if (res.data.code == 10000) {
          this.msgsuccess("保存成功！");
        } else {
          this.msgerror("保存失败！");
        }
      });
    },

    previewOutcall(phone) {
      var params = {};
      var tel = phone;
      params.tel = tel;
      params.requestUniqueId = "1233333";
      params.handleType = "previewOutcall";
      console.log("params==================" + params);
      this.creatA(params);
    },
  },
};
</script>

<style scoped>
.back-btn {
  position: absolute;
  top: 20px;
  left: 20px;
  cursor: pointer;
  color: #409eff;
}

.detail-content {
  padding: 15px;
  margin: 20px 15px;
  background-color: #fff;
  border: 1px solid #e2e2e4;
}
.back-button {
  margin-left: 20px;
  margin-top: 20px;
}
.item {
  display: flex;
  width: 270px;
  margin-top: 25px;
}
.term {
  margin-right: 10px;
}
.detail {
  margin-right: 10px;
}
</style>
